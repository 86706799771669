// src/Login.js
import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import fondoLogin from "../assets/Fondo.webp"

import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Login() {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('AAAAAAAAAA');
        setOpen(true);
        try {
            const response = await fetch('http://localhost:4000/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
    
            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('token', data.token); // Guardar el token en localStorage
                console.log('Inició sesión exitosamente');
                navigate('/protected');
                // Redirigir al usuario a una ruta protegida, por ejemplo
            } else {
                setError('Error al iniciar sesión');
                setOpen(true);
            }
        } catch (error) {
            setError('Error en la solicitud');
            setOpen(true);
        }
    };


    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 3,
                    boxShadow: 3,
                    borderRadius: 2,
                    width: '100%',
                    backgroundImage: `url(${fondoLogin})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <Typography component="h1" variant="h5">
                    Iniciar Sesión
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={formData.email}
                        onChange={handleChange}
                        sx={{
                            input: {
                                color: '#2e0b54', // Color del texto dentro del campo
                            },
                            label: {
                                color: '#2e0b54', // Color de la etiqueta del campo (cuando está en la parte superior)
                            }
                        }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Contraseña"
                        type="password"
                        autoComplete="current-password"
                        value={formData.password}
                        onChange={handleChange}
                        sx={{
                            input: {
                                color: '#2e0b54', // Color del texto dentro del campo
                            },
                            label: {
                                color: '#2e0b54', // Color de la etiqueta del campo (cuando está en la parte superior)
                            }
                        }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Iniciar Sesión
                    </Button>
                    <Typography variant="body2">
                        ¿No tienes una cuenta? <Link to="/register">Regístrate</Link>
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
}

export default Login;
