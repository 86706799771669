import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../assets/Logo1.webp';
import logo2 from '../assets/Logo2.webp';
import logo3 from '../assets/Logo3.webp';
import { Link } from 'react-router-dom';

function Header() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="static" sx={{ bgcolor: 'primary.main', zIndex: 3 }}>
            <Toolbar sx={{ padding: "10px" }}>
                <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                    <img src={logo} alt="Logo" style={{ height: '70px' }} />
                    <Typography variant="h6" component="div" sx={{ marginLeft: 2 }}>
                        The Lord of the Data
                    </Typography>
                </Box>
                <IconButton
                    size="large"
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={handleMenu}
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem component={Link} to="/" onClick={handleClose}>Página Principal</MenuItem>
                    <MenuItem component={Link} to="/login" onClick={handleClose}>Login</MenuItem>
                    <MenuItem component={Link} to="/register" onClick={handleClose}>Registro</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
}

export default Header;
