import React from 'react';
import HeroSection from '../components/HeroSection';
import ServicesSection from '../components/ServicesSection';
import AprendeGratisSection from '../components/AprendeGratis';
import ContactSection from '../components/ContactSection';

function LandingPage({ onOpenMenu }) {  // Recibe la prop onOpenMenu
    return (
        <>
            <HeroSection />
            <ServicesSection />
            <AprendeGratisSection onOpenMenu={onOpenMenu} />  {/* Pasa la prop onOpenMenu */}
            <ContactSection />
        </>
    );
}

export default LandingPage;
