// src/App.js
import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './components/Header';
import Footer from './components/Footer';
import Register from './sections/Registro';
import Login from './sections/Login';
import ParticlesComponent from "./components/FondoParticulas";
import ProtectedComponent from './cursos/test';
import DynamicLesson from './sections/DynamicLesson';
import LessonMenu from './sections/LessonMenu';
import LandingPage from './sections/LandingPage';

const PrivateRoute = ({ children }) => {
    const token = localStorage.getItem('token');
    if (!token) {
        return <Navigate to="/" />;
    }
    return children;
}

function App() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // Función para abrir el menú
    const handleOpenMenu = () => {
        setIsMenuOpen(true);
    };

    // Función para cerrar el menú
    const handleCloseMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <Router>
                        <Header style={{ flexShrink: 0 }} />
                        <div style={{ display: 'flex', flex: 1 }}>
                            
                                <ParticlesComponent />
                                <LessonMenu isOpen={isMenuOpen} onClose={handleCloseMenu} onOpen={handleOpenMenu} />
                                <div style={{ flex: '1', padding: '16px' }}>
                                
                                    <Routes>
                                        <Route path="/" element={<LandingPage onOpenMenu={handleOpenMenu} />} />
                                        <Route path="/login" element={<Login />} />
                                        <Route path="/register" element={<Register />} />
                                        <Route path="/protected" element={
                                            <PrivateRoute>
                                                <ProtectedComponent />
                                            </PrivateRoute>
                                        } />
                                        <Route path="/les/:path" element={<DynamicLesson />} />
                                    </Routes>
                                
                                </div>
                            
                        </div>
                        <Footer style={{ flexShrink: 0 }} />
                    </Router> 
                </div>
                
                           
            
        </ThemeProvider>
    );
}

export default App;
