import React from 'react';
import { Box, Grid, Typography, Paper } from '@mui/material';
import { motion } from 'framer-motion';

const services = [
    { title: "Desarrollo de Proyectos", description: "Proyectos personalizados en ciencia de datos." },
    { title: "Consultoría", description: "Consultoría en IA y ciencia de datos para mejorar tu negocio." },
    { title: "Capacitación", description: "Cursos y talleres para dominar la ciencia de datos." },
];

function ServicesSection() {
    return (
        <Box sx={{ padding: 8, backgroundColor:"rgba(255, 255, 255, 0.8)",margin: "1vh"}}>
            <Typography variant="h4" sx={{ textAlign: 'center', mb: 4 }}>
                Nuestros Servicios
            </Typography>
            <Grid container spacing={4}>
                {services.map((service, index) => (
                    <Grid item xs={12} md={4} key={index}>
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <Paper elevation={3} sx={{ padding: 4, textAlign: 'center' }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                    {service.title}
                                </Typography>
                                <Typography variant="body1">
                                    {service.description}
                                </Typography>
                            </Paper>
                        </motion.div>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default ServicesSection;
