import React from 'react';
import { Box, Grid, Typography, TextField, Button } from '@mui/material';

function ContactSection() {
    return (
        <Box id="contact-section" sx={{ padding: 8 , backgroundColor:"rgba(255, 255, 255, 0.1)",margin: "1vh"}}>
            <Typography variant="h4" sx={{ textAlign: 'center', mb: 4 }}>
                Contáctanos
            </Typography>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <TextField fullWidth label="Nombre" variant="outlined" sx={{ mb: 2 }} />
                    <TextField fullWidth label="Correo Electrónico" variant="outlined" sx={{ mb: 2 }} />
                    <TextField fullWidth label="Mensaje" variant="outlined" multiline rows={4} sx={{ mb: 2 }} />
                    <Button variant="contained" color="primary" size="large">
                        Enviar
                    </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{ height: '100%', backgroundColor: '#eee' }}>
                        {/* Aquí puedes agregar un mapa o información adicional */}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default ContactSection;
