import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { motion } from 'framer-motion';

function AprendeGratisSection({ onOpenMenu }) {
    return (
        <Box
            sx={{
                padding: 8,
                backgroundColor: '#2196f3',
                color: '#fff',
                textAlign: 'center',
                margin: "1vh"
            }}
        >
            <Typography variant="h4" sx={{ mb: 4 }}>
                Aprende Gratis
            </Typography>
            <Typography variant="h6" sx={{ mb: 4 }}>
                Accede a recursos gratuitos para aprender sobre ciencia de datos, inteligencia artificial y más para todos los niveles.
            </Typography>
            <motion.div
                whileHover={{ scale: 1.1 }}
            >
                <Button
                    onClick={onOpenMenu}
                    variant="contained"
                    color="secondary"
                    size="large"
                >
                    Explorar Cursos
                </Button>
            </motion.div>
        </Box>
    );
}

export default AprendeGratisSection;
