// src/Registro.js
import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import fondoRegistro from "../assets/Fondo.webp"
import { Link } from 'react-router-dom';

function Register() {
    const [formData, setFormData] = useState({
      firstName: '',
      lastName: '',
      motherLastName: '',
      matricula: '',
      email: '',
      password: '',
      confirmPassword: '',
    });

    const [passwordError, setPasswordError] = useState('');
    const [passwordStrength, setPasswordStrength] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        // Validación de contraseña en tiempo real
        if (name === 'password' || name === 'confirmPassword') {
            validatePassword(value);
            if (name === 'confirmPassword') {
                if (formData.password !== value) {
                    setPasswordError('Las contraseñas no coinciden.');
                } else {
                    setPasswordError('');
                }
            }
        }
    };

    const validatePassword = (password) => {
        const lengthRequirement = password.length >= 8;
        const upperCase = /[A-Z]/.test(password);
        const lowerCase = /[a-z]/.test(password);
        const number = /[0-9]/.test(password);
        const specialChar = /[!@#$%^&*]/.test(password);
    
        if (lengthRequirement && upperCase && lowerCase && number && specialChar) {
          setPasswordStrength('Fuerte');
        } else if (lengthRequirement && (upperCase || lowerCase) && number) {
          setPasswordStrength('Moderada');
        } else {
          setPasswordStrength('Débil');
        }
      };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.password !== formData.confirmPassword) {
          alert('Las contraseñas no coinciden.');
          return;
        }
        else if (passwordStrength !== "Fuerte" && passwordStrength !== "Moderada") {
            alert('Tu contraseña es muy débil, se requiere que sea mínimo Moderada.');
            return;
        }
        console.log('Form Data:', formData);
        // Aquí iría la lógica para enviar los datos al servidor
    };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 3,
            boxShadow: 3,
            borderRadius: 2,
            //bgcolor: 'background.paper',
            width: '100%',
            backgroundImage: `url(${fondoRegistro})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        }}
      >
        <Typography component="h1" variant="h5">
          Regístrate
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Nombres"
            name="firstName"
            autoComplete="given-name"
            autoFocus
            value={formData.firstName}
            onChange={handleChange}
            sx={{
              input: {
                color: '#2e0b54', // Color del texto dentro del campo
              },
              label: {
                color: '#2e0b54', // Color de la etiqueta del campo (cuando está en la parte superior)
              }
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Apellido Paterno"
            name="lastName"
            autoComplete="family-name"
            value={formData.lastName}
            onChange={handleChange}
            sx={{
              input: {
                color: '#2e0b54', // Color del texto dentro del campo
              },
              label: {
                color: '#2e0b54', // Color de la etiqueta del campo (cuando está en la parte superior)
              }
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Apellido Materno"
            name="motherLastName"
            autoComplete="family-name"
            value={formData.motherLastName}
            onChange={handleChange}
            sx={{
              input: {
                color: '#2e0b54', // Color del texto dentro del campo
              },
              label: {
                color: '#2e0b54', // Color de la etiqueta del campo (cuando está en la parte superior)
              }
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Matrícula"
            name="matricula"
            autoComplete="off"
            value={formData.matricula}
            onChange={handleChange}
            sx={{
              input: {
                color: '#2e0b54', // Color del texto dentro del campo
              },
              label: {
                color: '#2e0b54', // Color de la etiqueta del campo (cuando está en la parte superior)
              }
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email"
            name="email"
            autoComplete="email"
            value={formData.email}
            onChange={handleChange}
            sx={{
              input: {
                color: '#2e0b54', // Color del texto dentro del campo
              },
              label: {
                color: '#2e0b54', // Color de la etiqueta del campo (cuando está en la parte superior)
              }
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            autoComplete="new-password"
            value={formData.password}
            onChange={handleChange}
            sx={{
              input: {
                color: '#2e0b54', // Color del texto dentro del campo
              },
              label: {
                color: '#2e0b54', // Color de la etiqueta del campo (cuando está en la parte superior)
              }
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirma tu contraseña"
            type="password"
            autoComplete="new-password"
            value={formData.confirmPassword}
            onChange={handleChange}
            sx={{
              input: {
                color: '#2e0b54', // Color del texto dentro del campo
              },
              label: {
                color: '#2e0b54', // Color de la etiqueta del campo (cuando está en la parte superior)
              }
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Registrar
          </Button>
          <Typography variant="body2">
                        ¿Ya tienes una cuenta? <Link to="/">Accede aqui</Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default Register;
