import React, { useEffect, useState } from 'react';
import { List, ListItemButton, ListItem, ListItemText, Drawer, Divider, Typography, Box, Collapse } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

function LessonMenu({ isOpen, onClose , onOpen}) {
    const [clases, setClases] = useState([]);
    const [openClase, setOpenClase] = useState({});
    const location = useLocation();

    useEffect(() => {
        //fetch('http://localhost:8000/server.php?action=clases')  // Cambiamos el endpoint al nuevo backend PHP
        fetch('https://back.ai-data-lord.com/server.php?action=clases')
            .then(response => response.json())
            .then(data => {
                // Transformamos los datos para garantizar la compatibilidad
                const formattedClasses = data.map(clase => ({
                    ...clase,
                    lecciones: Array.isArray(clase.lecciones)
                        ? clase.lecciones // Si es array, lo mantenemos tal cual
                        : Object.values(clase.lecciones) // Si no, lo convertimos a array
                }));
    
                setClases(formattedClasses);
            })
            .catch(error => console.error('Error al cargar las clases:', error));
    }, []);
    

    

    const handleDrawerToggle = () => {
        if (isOpen) {
            onClose(); // Llama a la función para cerrar
        } else {
            onOpen(); // Llama a la función para abrir
        }
    };

    const handleClaseToggle = (clase) => {
        setOpenClase((prevOpenClase) => ({
            ...prevOpenClase,
            [clase]: !prevOpenClase[clase],
        }));
    };
    
    
    const getClaseColor = (clase) => {
        const foundClase = clases.find(cl => cl.clase === clase);
        const lecciones = foundClase.lecciones;
    
        console.error("AAA:", lecciones);
        console.error("BBB:", location.pathname);
    
        // Iterar sobre las lecciones y mostrar la ruta transformada para cada una
        lecciones.forEach(leccion => {
            const transformedPath = `/les/${leccion.path.split('/').join('-').split(' ').join('_')}`;
            console.log(`Generated path: ${transformedPath}`);
        });
    
        const isClaseActive = lecciones.some(leccion => {
            const transformedPath = `/les/${leccion.path.split('/').join('-').split(' ').join('_')}`;
            console.log(`Comparing: ${location.pathname} === ${transformedPath}`);
            return location.pathname === transformedPath;
        });
        return isClaseActive ? '#90caf9' : 'inherit'; // Cambiar color si la clase contiene la lección activa
    };

    return (
        <>
            <Box 
                sx={{ 
                    position: 'fixed', 
                    left: 0, 
                    top: 0, 
                    bottom: 0, 
                    width: 30, 
                    backgroundColor: 'rgba(0, 128, 0, 0.7)',  
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    zIndex: 0, 
                }}
                onClick={handleDrawerToggle}
            >
                <Typography 
                    variant="h6" 
                    sx={{ 
                        color: '#fff', 
                        writingMode: 'vertical-rl',  
                        textAlign: 'center',
                        fontWeight: 'bold',
                    }}
                >
                    Menú de Lecciones
                </Typography>
            </Box>
            <Drawer
                variant="temporary"
                anchor="left"
                open={isOpen}
                onClose={onClose}
                sx={{
                    width: 240,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                        width: 240,
                        boxSizing: 'border-box',
                        backgroundColor: '#f5f5f5',
                    },
                }}
            >
                <List>
                    <Typography 
                        variant="h6" 
                        sx={{ 
                            padding: 2, 
                            textAlign: 'center', 
                            fontWeight: 'bold',
                            backgroundColor: '#3f51b5',  // Fondo del título
                            color: '#fff'  // Color del texto del título
                        }}
                    >
                        Menú de Lecciones
                    </Typography>
                    {clases.map((clase, index) => (
                        <div key={index}>
                            <ListItemButton 
                                onClick={() => handleClaseToggle(clase.clase)}
                                sx={{
                                    backgroundColor: getClaseColor(clase.clase)  // Cambia el fondo si la clase contiene la lección activa
                                }}
                            >
                                <ListItemText primary={clase.clase.toUpperCase()} />
                                {openClase[clase.clase] ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={openClase[clase.clase]} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {/* Asegurarse que clase.lecciones sea un array */}
                                    {Array.isArray(clase.lecciones) && clase.lecciones.map((leccion, idx) => {
                                        const isActive = location.pathname === `/les/${(leccion.path.split('/').join('-').split(' ').join('_'))}`;
                                        return (
                                            <ListItemButton 
                                                key={idx} 
                                                component={Link} 
                                                to={`/les/${(leccion.path.split('/').join('-').split(' ').join('_'))}`}
                                                selected={isActive}
                                                sx={{
                                                    pl: 4,
                                                    backgroundColor: isActive ? 'rgba(0, 128, 0, 0.1)' : 'inherit',
                                                }}
                                            >
                                                <ListItemText primary={leccion.name} />
                                                {isActive && (
                                                    <Typography variant="caption" sx={{ marginLeft: 1, color: '#008000' }}>
                                                        (Actual)
                                                    </Typography>
                                                )}
                                            </ListItemButton>
                                        );
                                    })}
                                </List>
                            </Collapse>
                            <Divider />
                        </div>
                    ))}
                </List>
            </Drawer>
        </>
    );
}

export default LessonMenu;
