import React from 'react';

function ProtectedComponent() {
    return (
        <div>
            <h2>Este es un componente protegido</h2>
            <p>Solo puedes ver esto si estás autenticado.</p>
        </div>
    );
}

export default ProtectedComponent;