import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';

function DynamicLesson() {
    const { path } = useParams();
    const [lesson, setLesson] = useState(null);
    const [classes, setClasses] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetch('https://back.ai-data-lord.com/server.php?action=clases')
            .then(response => response.json())
            .then(data => {
                const formattedClasses = data.map(clase => ({
                    ...clase,
                    lecciones: Array.isArray(clase.lecciones)
                        ? clase.lecciones
                        : Object.values(clase.lecciones)
                }));
                setClasses(formattedClasses);
            })
            .catch(error => console.error('Error al cargar las clases:', error));
        
        let fullPath = `${path.split('-').join('/').split('_').join(' ')}`;
        fullPath= fullPath.split('/');
        fullPath[1]=encodeURI(fullPath[1]);
        fullPath[2]=encodeURI(fullPath[2]);
        fullPath=fullPath.join('/');
        
        console.error('Problemas2 ',fullPath);
        console.error('Problemas3 ',path);
        fetch(fullPath)
            .then(response => {
                
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => setLesson(data))
            .catch(error => {
                
                console.error('Error al cargar la lección:', error);
                setError(error.message);
            });
    }, [path]);

    if (error) return <Typography>Error: {error}</Typography>;
    if (!lesson) return <Typography>Loading...</Typography>;

    // Flatten the lessons array to easily find the current lesson's index
    const RecPath = `${path.split('-').join('/').split('_').join(' ')}`;
    const allLessons = classes.flatMap(c => c.lecciones);
    const currentIndex = allLessons.findIndex(lesson => lesson.path === RecPath);
    console.error("###########");
    console.error("lessons:",allLessons);
    console.error("path:",RecPath);

    const currentClass = RecPath.split('/')[2];
    const currentClassLessons = classes
        .find(c => c.clase === currentClass)?.lecciones || [];
    const currentIndex2 = currentClassLessons.findIndex(lesson => lesson.path === RecPath);

    const renderImage = (item) => {
        let width;
        switch (item.size) {
            case 'small':
                width = '30%';
                break;
            case 'medium':
                width = '50%';
                break;
            case 'large':
                width = '100%';
                break;
            default:
                width = '50%';
        }
        return (
            <Box key={item.src} sx={{ marginY: 4, textAlign: 'center' }}>
                <img src={item.src} alt={item.alt} style={{ maxWidth: width, height: 'auto' }} />
                {item.caption && (
                    <Typography variant="caption" display="block" sx={{ marginTop: 1 }}>
                        {item.caption}
                    </Typography>
                )}
            </Box>
        );
    };

    const renderList = (item) => {
        return (
            <Box key={item.items.join('-')} sx={{ marginY: 2, paddingLeft: item.style === 'ordered' ? 2 : 0 }}>
                <List sx={{ listStyleType: item.style === 'ordered' ? 'decimal' : 'disc', listStylePosition: 'outside', marginLeft: 4 }}>
                    {item.items.map((listItem, idx) => (
                        <ListItem key={idx} sx={{ display: 'list-item', paddingLeft: 0 }}>
                            <ListItemText primary={listItem} />
                        </ListItem>
                    ))}
                </List>
            </Box>
        );
    };

    const handleNavigation = (direction) => {
        if (direction === "next" && currentIndex < allLessons.length - 1) {
            navigate(`/les/${allLessons[currentIndex + 1].path.split('/').join('-').split(' ').join('_')}`);
        } else if (direction === "prev" && currentIndex > 0) {
            navigate(`/les/${allLessons[currentIndex - 1].path.split('/').join('-').split(' ').join('_')}`);
        }
    };

    return (
        <Container sx={{ paddingTop: 4, paddingBottom: 4, backgroundColor: "white" }}>
            
            <Typography variant="h3" component="h1" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                {lesson.title}
            </Typography>

            {lesson.estimated_time && (
                <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', color: '#757575' }}>
                    Tiempo estimado: {lesson.estimated_time}
                </Typography>
            )}

            {lesson.motivational_quote && (
                <Box sx={{ marginY: 4, textAlign: 'center', fontStyle: 'italic', backgroundColor: "#f0ce8d", padding: 2, borderRadius: '8px' }}>
                    <Typography variant="h5" gutterBottom>
                        {lesson.motivational_quote.text}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                        - {lesson.motivational_quote.author}
                    </Typography>
                </Box>
                
            )}

            {lesson.content.map((item, index) => {
                switch (item.type) {
                    case 'paragraph':
                        return (
                            <Box
                                key={index}
                                sx={{
                                    marginY: 2,
                                    padding: 2,
                                    backgroundColor: '#f9f9f9',
                                    border: '1px solid #e0e0e0',
                                    borderRadius: '8px',
                                    lineHeight: 1.6,
                                    textAlign: 'justify'
                                }}
                            >
                                <Typography variant="body1">
                                    {item.text}
                                </Typography>
                            </Box>
                        );
                    case 'introparagraph':
                        return (
                            <Box
                                key={index}
                                sx={{
                                    marginY: 2,
                                    padding: 2,
                                    backgroundColor: '#498621',
                                    border: '1px solid #e0e0e0',
                                    borderRadius: '8px',
                                    lineHeight: 1.6,
                                    textAlign: 'justify'
                                }}
                            >
                                <Typography variant="body1" sx={{ color: 'white' }}>
                                    {item.text}
                                </Typography>
                            </Box>
                        );
                    case 'quote':
                        return (
                            <Box key={index} sx={{ marginY: 4, textAlign: 'center', fontStyle: 'italic', backgroundColor: "#f0ce8d", padding: 2, borderRadius: '8px' }}>
                                <Typography variant="h5" gutterBottom>
                                    {item.text}
                                </Typography>
                                <Typography variant="h6" color="textSecondary">
                                    - {item.author}
                                </Typography>
                            </Box>
                        );
                    case 'tip':
                        return (
                            <Box key={index} sx={{ marginY: 4, padding: 2, borderLeft: '4px solid #1D3557', backgroundColor: '#f0f0f0' }}>
                                <Typography variant="body1">
                                    <strong>Tip:</strong> {item.text}
                                </Typography>
                            </Box>
                        );
                    case 'image':
                        return renderImage(item);
                    case 'video':
                        return (
                            <Box key={index} sx={{ marginY: 4, textAlign: 'center', position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
                                <iframe
                                    src={item.src}
                                    title="Video"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                ></iframe>
                            </Box>
                        );
                    case 'code':
                        return (
                            <SyntaxHighlighter key={index} language="python" style={darcula}>
                                {item.code}
                            </SyntaxHighlighter>
                        );
                    case 'subheader':
                        return (
                            <Typography key={index} variant="h5" component="h2" gutterBottom sx={{ marginTop: 4, marginBottom: 2, textAlign: 'center', color: '#2E3B55' }}>
                                {item.text}
                            </Typography>
                        );
                    case 'subsubheader':
                        return (
                            <Typography key={index} variant="h6" component="h3" gutterBottom sx={{ marginTop: 4, marginBottom: 2, color: "red" }}>
                                {item.text}
                            </Typography>
                        );
                    case 'section':
                        return (
                            <Box key={index} sx={{ marginY: 4, padding: 2, backgroundColor: '#e3f2fd', borderRadius: '8px', border: '1px solid #90caf9' }}>
                                <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                                    {item.header}
                                </Typography>
                                <List sx={{ listStyleType: 'disc', listStylePosition: 'outside', marginLeft: 4 }}>
                                    {item.items.map((listItem, idx) => (
                                        <ListItem key={idx} sx={{ display: 'list-item', paddingLeft: 0 }}>
                                            <ListItemText primary={listItem} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        );
                    case 'list':
                        return renderList(item);
                    case 'ads':
                        return (
                            <Box key={index} sx={{ marginY: 4, textAlign: 'center' }}>
                                <Typography variant="body1" sx={{ padding: 2, backgroundColor: '#e0f7fa' }}>
                                    {item.content}
                                </Typography>
                            </Box>
                        );
                    default:
                        return null;
                }
            })}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 4 }}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={currentIndex2 === 0}
                    onClick={() => handleNavigation("prev")}
                >
                    Lección Anterior
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={currentIndex2 === currentClassLessons.length - 1}
                    onClick={() => handleNavigation("next")}
                >
                    Siguiente Lección
                </Button>
            </Box>
        </Container>
    );
}

export default DynamicLesson;