// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#7C4DFF',
    },
    secondary: {
      main: '#B47CFF',
    },
    lightPurple: {
      main: '#D1B3FF',
    },
    text: {
      primary: '#333333',
      secondary: '#E6E6E6',
    },
    background: {
      default: '#52FBDE',
      paper: '#FFFFFF',
    },
  },

});

export default theme;
