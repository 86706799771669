import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { motion } from 'framer-motion';


function HeroSection() {
    const scrollToContact = () => {
        const contactSection = document.getElementById('contact-section');
        if (contactSection) {
            contactSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Box
            sx={{
                //backgroundImage: `url(${fondohero})`, // Usar backgroundImage
                //backgroundSize: 'contain', // Ajustar para que cubra toda la sección
                //backgroundPosition: 'center', // Centrar la imagen
                //backgroundRepeat: 'no-repeat', // Evitar que la imagen se repita
                height: '80vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                color: '#fff',
                backgroundColor: "#664c40b6", // Color de fondo adicional
                margin: "1vh"
            }}
        >
            <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
            >
                <Typography variant="h2" sx={{ fontWeight: 'bold' }}>
                    Transforma tus datos en decisiones inteligentes
                </Typography>
                <Typography variant="h5" sx={{ marginY: 2 }}>
                    Soluciones de Ciencia de Datos e Inteligencia Artificial para tu negocio
                </Typography>
                <Button
                    onClick={scrollToContact}
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{ mt: 4 }}
                >
                    Contáctanos
                </Button>
            </motion.div>
        </Box>
    );
}

export default HeroSection;

